import React, {
  useEffect,
} from 'react'
import {
  graphql, Link
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Header from '../../components/Header'
import PageHero from '../../components/Blocks/PageHero'
import Footer from '../../components/Footer'
import {
  Page, Main,
} from '../../components/StyledComponents'
import TeamAlPacifico from '../../assets/images/about/team-al-pacifico.png'
import Title from '../../components/Base/Title'
import Paragraph from '../../components/Base/Paragraph'
import Section from '../../components/Base/Section'
import SectionWrap from '../../components/Base/Section/Wrap'
import IconCustom from '../../components/Base/Icon/Custom'
import BottomOverlay from '../../components/Blocks/BottomOverlay'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-about-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-about.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({
  data,
}) => {
  useEffect(() => {
    const hash = window.location.hash.substring(1)
    if (hash.length > 0) {
      document.getElementById(hash).scrollIntoView()
    }
  })

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]
  return (
    <Layout>
      <SEO
        title='Patient Intake Software'
        description="ChartMedic's advanced patient intake software improves patient experience and clinician workflow. Fill out our free form today to get started."
      />
      <Page>
        <Header />
        <Main>
          <PageHero
            background={sources}
            title='Patient Intake Software'
          />
          <SectionWrap theme='grey'>
            <Section center>
              <IconCustom theme='thumbsup' margined />
              <Title level={2} margined>
                Simplifying the Practice of Medicine
              </Title>
              <Title level={3}>
                Medicine should be about patients, not paperwork.
              </Title>
              <Section theme='text-box' center>
                <Paragraph>
                  Our team includes medical professionals with specialties ranging
                  from emergency medicine to infectious diseases. We have worked
                  in major hospitals, small clinics, and everything in between.
                </Paragraph>
                <Paragraph>
                  We are convinced that current processes surrounding capturing
                  and recording patient health data can be vastly improved. We
                  have seen technology get in the way of meaningful
                  patient-provider interactions, reducing one-on-one communication
                  and degrading insights learned from those conversations. We have
                  felt patient frustration when presented with a stack of paper
                  forms to fill out, only to have the very same questions repeated
                  and verified during an appointment.
                </Paragraph>
                <Paragraph>
                  Treatment decisions hinge on a large amount of information. The
                  ChartMedic system captures essential patient information for
                  common clinical scenarios and presents it to the provider at the
                  time of the appointment, so that the recording of complete
                  information in the electronic medical record doesn‘t distract
                  from patient interaction.
                </Paragraph>
                <Paragraph>
                  We have engaged in years of research and development to bring
                  you an end-to-end system, with turn-key access to complete
                  patient health questionnaires across specialties, clinical
                  scenarios, and languages. Our system offers user roles for all
                  participants in the delivery of health care.
                </Paragraph>
                <Paragraph>
                  We would love to see the ChartMedic system used across
                  hospitals, clinics, and medical centers to simplify and improve
                  the practice of medicine. We welcome all questions or feedback.
                </Paragraph>
              </Section>
              <Section theme='text-box' center>
                <Title level={2} margined>
                  ChartMedic's Patient Intake Software
                </Title>
                <Paragraph>
                  With the ChartMedic mobile app, patients and healthcare providers alike can 
                  say goodbye to tiring over stacks of medical paperwork. A new way to document, 
                  access, and secure medical information, <Link to='/how-it-works/'>our patient intake software</Link> handles 
                  everything from the initial patient questionnaire to the configuration of 
                  chart notes and storage of clinical data.
                </Paragraph>
                <Paragraph>
                  Upon arrival, patient check-in is made efficient and straightforward with our 
                  user-friendly app that bypasses the need for typing long responses. Developed by 
                  healthcare professionals with years of experience, ChartMedic's multiple-choice 
                  questionnaires are made specific to various clinical settings ranging from broken 
                  bones to chronic diseases. Once all medical information is inputted into the mobile 
                  questionnaire, entries are transferred to a cloud for processing.
                </Paragraph>
                <Paragraph>
                  Patient details are configured into sections that group together contextual information 
                  such as medication usage, past medical history, and relevant lifestyle habits. As a 
                  reference made accessible to each on-site healthcare provider, the app-generated chart 
                  eliminates any time spent repeating symptoms to physicians, nurses, or other staff members.
                </Paragraph>
                <Paragraph>
                  Stored in Epic, an electronic medical record system, all patient information is kept secure 
                  without any personally identifying information. Our software technology uses icons and birth 
                  years to associate patients with their recorded questionnaires, ensuring that they remain 
                  anonymous when their data is uploaded to the cloud and Epic.
                </Paragraph>
              </Section>
            </Section>
          </SectionWrap>
          <Section margined short center>
            <img
              style={{
                marginBottom: '30px',
              }}
              src={TeamAlPacifico}
              alt='Al Pacifico'
            />
            <Title level={2} margined>
              About Our Founder
            </Title>
            <Title level={3}>
              Dr. Albert Pacifico
            </Title>
            <Paragraph>
              ChartMedic is the brainchild of Al Pacifico, a practicing internist in Seattle, Washington. 
              He grew up coding (starting with an Apple II in 1978, which was a gift from a grateful business associate 
              of his father‘s) and put it away while studying to be a physician, graduating from Duke University School 
              of Medicine in 1990. At that time, MRI scanners were novel technology, and doctors had the time to listen 
              to their patients.
            </Paragraph>
            <Paragraph>
              With the advent of electronic medical records, he always felt it could (and, outside of the realm of 
              medicine, often was) be done better. The idea for ChartMedic came to him in the spring of 2016. Shortly after 
              that, his growing dissatisfaction with what he saw as the substitution of technology for simply listening to 
              what patients have to say and asking the right questions prompted him to develop ChartMedic.
            </Paragraph>
            <Paragraph>
              Learn more about how ChartMedic could change the medical landscape for patients and providers alike by <Link to='/get-started/'>filling out our form</Link>.
            </Paragraph>
          </Section>
        </Main>
      </Page>
      <Footer />
      <BottomOverlay />
    </Layout>
  )
}
